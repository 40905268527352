body {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.navbar-vertical.navbar-expand-xs {
  max-width: 17rem !important;
}
.text-main {
  color: #4168a3 !important;
}
.text-black {
  color: #000 !important;
}
.text-tertiary {
  color: #9b9b9b !important;
}
.text-md {
  font-size: 1rem !important;
}
.sidebar-gradient-primary {
  background: rgb(248, 249, 250);
  background: linear-gradient(
    90deg,
    rgba(248, 249, 250, 0) 0%,
    rgba(248, 249, 250, 0.5) 80%,
    rgba(248, 249, 250, 1) 100%
  );
}
.sidebar-gradient-secondary {
  background: #ececec;
  background: linear-gradient(
    90deg,
    rgba(236, 236, 236, 0) 0%,
    rgba(236, 236, 236, 0.5) 80%,
    rgba(236, 236, 236, 1) 100%
  );
}
.navbar-vertical .navbar-nav .nav-item.active {
  background-color: #ececec;
  border-radius: 0.5rem;
}
/** Animation **/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typing {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  /* Change 'steps(40, end)' to match the number of characters in your text */
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spin {
  animation: spin 1s linear infinite;
}

/** Scrollbar **/
::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
}
::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: hsla(0, 0%, 89%, 0.8);
  border-radius: 9999px;
  border: 1px solid #fff;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}
::-webkit-scrollbar:hover,
::-webkit-scrollbar:active {
  opacity: 1; /* fully visible on hover/active */
}
