a.active[data-scroll] {
    background: #e9ecef;
}

.btn-custom {
    --bs-btn-bg: #3572EF !important;
    --bs-btn-border-color: #3572EF !important;
    --bs-btn-active-bg: #3572EF !important;
    --bs-btn-active-border-color: #3572EF !important;
    background-color: #3572EF !important;
    color: #fff !important;
}

.text-3572EF {
    color: #3572EF !important;
}

.text-3572EF a:hover {
    color: #29334A !important;
}

.text-29334A {
    color: #29334A !important;
}

.btn-custom:hover,
.btn.bg-gradient-custom:hover {
    background-color: #29334A !important;
    border-color: #29334A;
}


.text-gradient.text-custom,
.bg-gradient-custom {
    background-image: linear-gradient(310deg, #3572EF, #3572EF);
}

.custom-nav-link {
    --bs-nav-link-color: #3572EF !important;
    --bs-link-color: #3572EF !important;
}

.resize-none {
    resize: none;
}