// Perspective effect
@include media-breakpoint-up(lg) {
  .change-perspective {
    transform: $transform-perspective;
  }

  .change-perspective-inverse {
    transform: $transform-perspective-inverse;
  }

  .perspective-right {
    transform: $transform-perspective-right;
    right: 300px;
    cursor: pointer;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    
    &:hover {
      transform: scale(1) perspective(2000px) rotateY(-15deg) rotateX(0deg) rotate(0deg);
    }
  }

  .perspective-right-sm {
    transform: $transform-perspective-right-sm;
    right: 30px;
  }

  .perspective-right-xs {
    transform: $transform-perspective-right-xs;
    right: -240px;
    top: -30px;
  }
}
// Device Wrapper
.device-wrapper {
  border-radius: $device-wrapper-radius;
  box-shadow: $device-wrapper-box-shadow;
}

// sidebar caret RTL
.rotate-caret {
  .collapse {
    .nav-link:after {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
// Colored Shadow on Cards images
.colored-shadow {
  transform: scale($colored-shadow-scale);
  top: $colored-shadow-top;
  filter: blur($colored-shadow-blur);
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;

  &.avatar-xs {
    transform: scale($colored-shadow-scale-avatar);
    width: $avatar-xs-width;
    height: $avatar-xs-height;
  }

  &.avatar-sm {
    transform: scale($colored-shadow-scale-avatar);
    width: $avatar-sm-width;
    height: $avatar-sm-height;
  }

  &.avatar-lg {
    transform: scale($colored-shadow-scale-avatar);
    width: $avatar-lg-width;
    height: $avatar-lg-height;
  }

  &.avatar-xl {
    transform: scale($colored-shadow-scale-avatar);
    width: $avatar-xl-width;
    height: $avatar-xl-height;
  }

  &.avatar-xxl {
    transform: scale($colored-shadow-scale-avatar);
    width: $avatar-xxl-width;
    height: $avatar-xxl-height;
  }
}

// Width in PX
.width-32-px {
  width: $width-32-px;
}

.width-48-px {
  width: $width-48-px;
}

.width-64-px {
  width: $width-64-px;
}

// Animated shapes
.animated-blur {
  filter: blur(40px);
}

.animated-blur-1 {
  animation: animation-blur-one 8s infinite;
}

.animated-blur-2 {
  animation: animation-blur-one 4s infinite;
}

.animated-blur-3 {
  animation: animation-blur-two 6s infinite;
}

.animated-blur-4 {
  animation: animation-blur-two 5s infinite;
}
@keyframes animation-blur-one {
  0% {
    transform: scale(.8);
  }

  25% {
    transform: translateX(10%);
  }

  50% {
    transform: scale(1.2);
  }

  75% {
    transform: translateX(5%);
  }

  100% {
    transform: scale(.8);
  }
}
@keyframes animation-blur-two {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1.2);
  }
}

// Text with border
.text-border {
  &:after,
  &:before {
    content: "";
    display: $text-border-display;
    width: $text-border-width;
    height: $text-border-height;
    position: $text-border-position;
    vertical-align: $text-border-vertical-align;
  }

  &:after{
    background: $text-border-bg-color-after;
  }

  &:before{
    background: $text-border-bg-color-before;
  }

  &:before {
    right: $text-border-before-right;
    margin-left: $text-border-before-ml;
  }

  &:after {
    left: $text-border-after-left;
    margin-right: $text-border-after-mr;
  }
}

a[data-scroll]{
  border-radius: $border-radius-md;
   &:hover{
    background: $gray-200;
    color: $h-color;
  }
}

// Round Slider Styling
round-slider {
  max-width: $round-slider-m-width;
  transition: $round-slider-transition;
}

round-slider.scaling:hover {
  max-width: $round-slider-scale-m-width;
}


// Smart Home - Room Selectpicker
.room-select {
  .choices {
    .choices__inner {
      background: $white;
      border-color: $white;
    }
  }
}

// Page Sections Breadcrumb
@include media-breakpoint-down(lg) {
  .page-headers-sections {
    .container {
      .row {
        .col-lg-12.mx-auto > .mb-4.w-25 {
          width: 50% !important;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .page-headers-sections {
    .container {
      .row {
        .col-lg-12.mx-auto > .mb-4.w-25 {
          width: 100% !important;
        }
      }
    }
  }
}
// Coming Soon Page
.transform-rotate {
  transform: $coming-rotate-transform;
}

// Landing Page

.landing {
  > div {
    background-image: radial-gradient(circle at bottom right, #3c9add, #191452);
  }

  .navbar-vertical {
    .navbar-brand {
      color: $white;
    }
    hr {
      background-image: $hr-vertical-bg-image-light;
    }

    .navbar-nav {
      > .nav-item .nav-link[data-bs-toggle='collapse'].active {
        background-color: rgba(246, 249, 252, .25) !important;
        box-shadow: none !important;
      }

      > .nav-item .icon .ni {
        color: $white !important;
      }

      .nav-item {
        .collapse,
        .collapsing {
          .nav {
            .nav-item {
              .nav-link {
                color: $white !important;
                opacity: .8;
              }
            }
          }
        }
        .nav-link {
          color: $white !important;
  
          &[data-bs-toggle="collapse"]:after {
            color: $white;
          }
        }
        h6 {
          color: $white;
          opacity: 1;
        }
      }
    }
  }
}
